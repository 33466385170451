import "./index.scss"
import React, { useState, useEffect } from "react"
import { useForm } from "react-form"
import WebsiteLayout from "../../components/layout/inner-layout/inner-layout"
import Button from "../../components/common/button/button"
import TextField from "../../components/common/form/text-field/text-field"
import styles from "../../components/common/button/button.module.scss"

const SignUpUser = ({ closeHandler, signUp }) => {
  const [submitAttempted, setSubmitAttempted] = useState()

  const onSignClick = event => {
    const { name, value } = event.target
    setUsers({ ...users, [name]: value })
  }

  const onSaveClick = () => {
    setSubmitAttempted(true)
  }

  const formInstance = useForm({
    debugForm: false,
    onSubmit: values => {
      console.log("onSubmit", values)
      signUp(values)
    },
    validate: (values, instance) => {
      setSubmitAttempted(false)
    },
  })

  const { Form, meta, pushFieldValue } = formInstance

  return (
    <div>
      <Form>
        <div className="addModalShow">
          <div className="modal-body">
            <TextField
              label="User Name"
              field="name"
              validate={value => {
                return !value ? "This is required!" : false
              }}
              mandatory
            />
            <TextField
              label="Admin Email"
              field="admins"
              validate={value => {
                return !value ? "This is required!" : false
              }}
              mandatory
            />
            <TextField
              label="Role"
              field="role"
              validate={value => {
                return !value ? "This is required!" : false
              }}
              mandatory
            />
            <div className={styles.action}>
              <div className={styles.buttonWrap}>
                <Button
                  id="save-company-data"
                  label="Save"
                  type="submit"
                  btnStyle="primary"
                  btnSize="small"
                  fullWidth
                  prependIcon="MdSave"
                  onClick={onSaveClick}
                />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}
export default SignUpUser
